/**
 * Модель для работы с пользователями
 */

import { createModel, ModelConfig, RematchDispatch } from '@rematch/core';

import UsersService, { IUserBody } from '../services/UsersService';
import NotificationsService from '../services/NotificationsService';
import ProtectionsService from '../services/ProtectionsService';
import { Dispatch, iRootState } from '../store';
import { ICoordXY, IId, INotification, ISelect, IUser, IUserMap } from './types';
import PositionsService from '../services/PositionsService';

export interface IData {
  protections: ISelect[];
}

interface IState {
  item: IUser | null;
  users: IUserMap[];
  data: IData;
  notifications: INotification[];
  isFocus: boolean;
}

const initialState: IState = {
  item: null,
  users: [],
  data: {
    protections: []
  },
  notifications: [],
  isFocus: true
};

const findByUserId = (users: IUserMap[], userId: IId) => users.find(user => user.id === userId);

const model: ModelConfig<IState> = {
  state: initialState,
  reducers: {
    setUser(state: IState, payload: IUser): IState {
      return {
        ...state,
        item: payload
      };
    },
    setUsers(state: IState, payload: IUserMap[]): IState {
      return {
        ...state,
        users: payload
      };
    },
    setData(state: IState, payload: IData): IState {
      return {
        ...state,
        data: {
          ...state.data,
          ...payload
        }
      };
    },
    setNotifications(state: IState, payload: INotification[]): IState {
      return {
        ...state,
        notifications: payload
      };
    },
    setIsFocus(state: IState, payload: boolean): IState {
      return {
        ...state,
        isFocus: payload
      };
    }
  },
  effects: (dispatch: RematchDispatch) => {
    const d = dispatch as Dispatch;

    return {
      async getUser(userId: string, rootState: iRootState) {
        const { data } = await UsersService.getUserEmployees(userId);

        const { users } = rootState.infrastructureUsers;

        const dataUsers = users.map(user => {
          const { id, position, charge } = user;

          return id === userId ? { ...data, position, charge } : user;
        });

        this.setUsers(dataUsers);

        this.setUser(data);
      },
      focusUser(position: ICoordXY) {
        // const payload = {
        //   center: position,
        //   zoom: 20
        // };
        const payload = { center: position };

        d.infrastructureMap.setFocus(payload);
      },
      resetUser() {
        this.setUser(initialState.item);
      },
      async updateUser(formBody: IUserBody) {
        const { avatar, ...body } = formBody;

        const { username } = body;

        try {
          const promises = [UsersService.updateOrCreate(body)];

          if (avatar) {
            const formData = new FormData();
            formData.append('file', avatar);
            formData.append('userName', username);

            promises.push(UsersService.updateOrCreateAvatar(formData));
          }

          await Promise.all(promises);

          this.getUser(username);
        } catch (error) {
          return error;
        }
      },
      async alertUser(payload: any, rootState: iRootState) {
        const { userId, ...trackInfo } = payload;
        const { users } = rootState.infrastructureUsers;
        let data = [];
        data = users.map(user => (user.id === userId ? { ...user, ...trackInfo } : user));
        // console.log(data, payload);
        this.setUsers(data);
      },

      async getUsers(data: any, rootState: iRootState) {
        const { payload, mapId } = data;
        const { list } = rootState.alerts; // Получаем список алертов
        const { users } = rootState.infrastructureUsers; // Получаем текущий массив пользователей
        const { item } = rootState.infrastructureUsers; // Получаем карточку активного сотрудника

        // Приводим payload к массиву, если это объект
        const payloadArray = Array.isArray(payload) ? payload : [payload];
        const updatedUsers = await Promise.all(
          payloadArray.map(async userMapData => {
            const { userId, trackInfo } = userMapData;

            // Определяем цвет метки на карте
            const trackInfoColor = Boolean(list.find(alert => alert.userId === userId))
              ? { trackInfo: { ...trackInfo, mapColor: 'red' } }
              : userMapData.position.type === 'Clear'
              ? { trackInfo: { ...trackInfo, mapColor: 'gray' } }
              : { ...trackInfo };

            // Если карточка сотрудника активна, обновляем данные
            if (item && item.id === userId) {
              this.setUser({ ...item, ...userMapData, ...trackInfoColor });
            }

            // Проверяем, есть ли пользователь в массиве
            const exists = findByUserId(users, userId);

            if (exists) {
              // Если пользователь существует, обновляем его данные
              return {
                ...users.find(user => user.id === userId), // Ищем существующего пользователя
                ...userMapData,
                ...trackInfoColor
              };
            } else {
              // Если это новый пользователь, запрашиваем информацию и добавляем
              const { data: user } = await UsersService.getUserEmployees(userId);
              return {
                ...user,
                ...userMapData,
                ...trackInfoColor
              };
            }
          })
        );

        // Фильтруем удаленные пользователей
        // const filteredUsers = updatedUsers.filter(user => user.position.type !== 'Clear');

        // Обединяем с текущим массивом пользователей
        const combinedArray = [...users, ...updatedUsers];
        const uniqueMap = new Map(combinedArray.map(user => [user.id, user]));
        const result = Array.from(uniqueMap.values());

        // Устанавливаем обновленный массив пользователей
        this.setUsers(result);
      },

      async setUsersListInMap(payload: IId, rootState: iRootState) {
        const { list } = rootState.alerts; // Получаем список алертов
        // const { users } = rootState.infrastructureUsers; // Получаем текущий массив пользователей
        const { item } = rootState.infrastructureUsers; // Получаем карточку активного сотрудника

        const { data } = await PositionsService.getUsers({
          mapId: payload,
          scope: 'HAS_ARCHIVE_POSITION'
        });

        const dataMod = await data.map(
          (user: {
            user: { id: IId; username: string };
            trackInfo: {
              measurements: [{ type: string; value: string }];
              mapColor: string;
              lastPosition: {};
            };
          }) => {
            const measurements = user.trackInfo.measurements;
            const chargeInfo =
              measurements && measurements.find(element => element.type === 'DEVICE_CHARGE');
            return {
              ...user.user,
              mapColor: user.trackInfo.mapColor,
              position: user.trackInfo.lastPosition,
              charge: {
                percent: (chargeInfo && chargeInfo.value) || null
              },
              trackInfo: user.trackInfo
            };
          }
        );

        // Приводим payload к массиву, если это объект
        const payloadArray = Array.isArray(dataMod) ? dataMod : [dataMod];
        const updatedUsers = await Promise.all(
          payloadArray.map(async userMapData => {
            const { id: userId, trackInfo } = userMapData;

            // Определяем цвет метки на карте
            const trackInfoColor = Boolean(list.find(alert => alert.userId === userId))
              ? { trackInfo: { ...trackInfo, mapColor: 'red' } }
              : userMapData.position.type === 'Clear'
              ? { trackInfo: { ...trackInfo, mapColor: 'gray' } }
              : { ...trackInfo };

            // Если карточка сотрудника активна, обновляем данные
            if (item && item.id === userId) {
              this.setUser({ ...item, ...userMapData, ...trackInfoColor });
            }

            return {
              ...userMapData,
              ...trackInfoColor
            };
          })
        );
        return this.setUsers(updatedUsers);
      },
      resetUsers() {
        this.setUsers(initialState.users);
      },
      async getData() {
        const {
          data: { data: protections }
        } = await ProtectionsService.getTypeAll({ pageSize: 100 });

        this.setData({ protections });
      },
      async getNotifications(employeesList: IId) {
        const {
          data: { data }
        } = await NotificationsService.getFullAll({ employeesList });

        this.setNotifications(data);
      },
      focusOn() {
        this.setIsFocus(true);
      },
      focusOff() {
        this.setIsFocus(false);
      }
    };
  }
};

export const infrastructureUsers: typeof model = createModel(model);
